import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconHeart,
} from '../../../components';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import IconSearch from '../../IconSearch/IconSearch';
import IconMessage from '../../IconMessage/IconMessage';
import IconBag from '../../IconBag/IconBag';
import IconUser from '../../IconUser/IconUser';
import { useMyContext } from '../../../contexts/StateHolder';
import Select from 'react-select';
import css from './TopbarDesktop.module.css';
import { createResourceLocatorString } from '../../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isArrayLength } from '../../../util/dataExtractors';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    bookmarks,
    dashboardHeader,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [isSearchToggle, setSearchToggle] = useState(false);
  const { cartItems: localBookmarks } = useMyContext();

  const history = useHistory();

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(
    rootClassName || isAuthenticated ? css.root : css.authRoot,
    className,
    {
      [css.dashboardHeader]: dashboardHeader,
    }
  );

  const search = (
    <div className={css.searchExpanded}>
      <TopbarSearchForm
        className={css.searchLink}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={appConfig}
      />
      <span className={css.crossIcon} onClick={() => setSearchToggle(!isSearchToggle)}>
        &times;
      </span>
    </div>
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  // const inboxLink = authenticatedOnClientSide ? (
  //   <NamedLink
  //     className={css.navLink}
  //     name="InboxPage"
  //     params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
  //   >
  //     <span className={css.inbox}>
  //       <FormattedMessage id="TopbarDesktop.inbox" />
  //       {notificationDot}
  //     </span>
  //   </NamedLink>
  // ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.menuLink}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <a
            href="/listings"
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </a>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: 'all' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.purchasesAndSales" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavouritesPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavouritesPage'))}
            name="FavouritesPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.FavouritesPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.navLink}>
      <IconUser />
    </NamedLink>
  );

  const cartLink = (
    <NamedLink rel="nofollow" name="CartPage" className={css.navLink}>
      <IconBag />
      {isArrayLength(localBookmarks) ? (
        <span className={css.badge}>{localBookmarks?.length}</span>
      ) : null}
    </NamedLink>
  );

  const favLink = (
    <NamedLink rel="nofollow" name="FavouritesPage" className={css.navLink}>
      <span className={css.iconHeart}>
        <IconHeart />
      </span>
    </NamedLink>
  );

  const messagesLink = (
    <NamedLink
      rel="nofollow"
      name="MessagesOrderPage"
      params={{ tab: 'enquiry' }}
      className={css.navLink}
    >
      <span className={css.iconMessage}>
        <IconMessage />
      </span>
    </NamedLink>
  );

  const searchToggle = (
    <span className={classNames(css.navLink)} onClick={() => setSearchToggle(!isSearchToggle)}>
      <IconSearch />
    </span>
  );
  const options = [
    { value: 'CostumeSearchPage', label: 'All Costumes' },
    {
      value: 'CostumeSearchPage',
      label: 'Single Costumes',
      search: 'Individual',
      key: 'pub_listingType',
    },
    {
      value: 'CostumeSearchPage',
      label: 'Classical Tutus',
      search: 'Classical-Tutu',
      key: 'pub_type',
    },
    { value: 'CostumeSearchPage', label: 'Costume Lots', search: 'Lot', key: 'pub_listingType' },
    // { value: 'CostumeRentalsSearchPage', label: 'Costumes Rentals' },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const customSelectStyles = {
    control: (styles, { isFocused, isDisabled, isSelected }) => ({
      ...styles,
      backgroundColor: '#fff',
      border: 'none',
      borderRadius: '0px',
      boxShadow: 'none',
      color: '#4a4a4a',
      fontSize: '15px',
      lineHeight: '100%',
      letterSpacing: '-0.04em',
      height: '45px',
      paddingLeft: '0px',
      paddingRight: '0px',
      minHeight: '45px',
      margin: '0px',
      padding: '0px 0px 0px 20px',
      fontWeight: '600',
      position: 'relative',
      cursor: 'pointer !important',
    }),
    valueContainer: (styles, { isFocused, isDisabled, isSelected }) => ({
      ...styles,
      color: isSelected ? '#4a4a4a' : '#B2B2B2',
      height: '100%',
      padding: '0px',
      margin: '0px',
      fontWeight: '600',
    }),
    placeholder: (styles, { isFocused, isDisabled, isSelected }) => ({
      ...styles,
      color: '#4a4a4a',
      padding: '0px',
      margin: '0px',
      fontWeight: '600',
    }),
    indicatorsContainer: (styles, { isFocused, isDisabled, isSelected }) => ({
      ...styles,
      padding: '0px',
      margin: '0px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: '0px',
      margin: '0px',
      transition: 'all .2s ease',
      transform: state.isFocused.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    input: (styles, { isFocused, isDisabled, isSelected }) => ({
      ...styles,
      color: isSelected ? '#4a4a4a' : '#B2B2B2',
      height: '100%',
      padding: '0px',
      margin: '0px',
      fontWeight: '600',
    }),
    indicatorSeparator: styles => ({ display: 'none', padding: '0px', margin: '0px' }),
    option: (styles, { isFocused, isDisabled, isSelected }) => ({
      ...styles,
      color: '#4a4a4a',
      padding: '15px',
      fontSize: '15px',
      lineHeight: '100%',
      letterSpacing: '-0.04em',
      cursor: 'pointer !important',
      backgroundColor: isDisabled ? null : isFocused ? '#eee' : isSelected ? '#FFF' : null,
      ':hover': {
        backgroundColor: '#eee',
        color: '#4a4a4a',
      },
    }),
    singleValue: (styles, { isFocused, isDisabled, isSelected }) => ({
      ...styles,
      color: isSelected ? '#4a4a4a' : '#4a4a4a',
      fontWeight: '600',
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      backgroundColor: '#fff',
      color: '#4a4a4a',
      position: 'absolute',
      top: '100%',
      left: '0',
      right: '0',
      width: '100%',
      minWidth: '200px',
      padding: '0px',
    }),
    menuList: base => ({
      ...base,
      padding: '0px',
      backgroundColor: '#fff',
      fontSize: '15px',
      lineHeight: '100%',
      letterSpacing: '-0.04em',
      color: '#4a4a4a',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#4a4a4a',
      padding: '0px',
    }),
  };
  return (
    <nav className={classes}>
      {isSearchToggle ? (
        search
      ) : (
        <div className={css.fixedWidthContainer}>
          <div className={css.leftNav}>
            <NamedLink className={css.logoLink} name="LandingPage">
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
            <NamedLink
              className={css.createListingLink}
              name="SearchPage"
              to={{
                search: '?pub_category=dancewear&pub_type=Leotard',
              }}
            >
              <span className={css.createListing}>Leotards</span>
            </NamedLink>
            <NamedLink
              className={css.createListingLink}
              name="SearchPage"
              to={{ search: '?pub_category=dancewear&pub_type=Warmups' }}
            >
              <span className={css.createListing}>Warmups</span>
            </NamedLink>
            <NamedLink
              className={css.createListingLink}
              name="SearchPage"
              to={{ search: '?pub_category=shoes' }}
            >
              <span className={css.createListing}>Shoes</span>
            </NamedLink>
            <Select
              styles={customSelectStyles}
              defaultValue={selectedOption}
              onChange={o => {
                const search = o?.search ? { [o.key]: o.search } : {};
                history.push(
                  createResourceLocatorString(o.value, routeConfiguration(), {}, search)
                );
              }}
              options={options}
              isSearchable={false}
              className={css.createListingSelect}
              placeholder="Costumes"
            />

            {/* <NamedLink className={css.createListingLink} name="CostumeSearchPage">
              <span className={css.createListing}>Costumes</span>
            </NamedLink>
            <NamedLink className={css.createListingLink} name="CostumeRentalsSearchPage">
              <span className={css.createListing}>Costumes Rentals</span>
            </NamedLink> */}
            <NamedLink className={css.createListingLink} name="SearchPage">
              <span className={css.createListing}>Show All</span>
            </NamedLink>
          </div>
          <div className={css.rightNav}>
            <a rel="nofollow" className={css.createListingLink} href="/l/new">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </a>
            {isAuthenticated ? (
              <NamedLink className={css.createListingLink} name="DashboardPage">
                <span className={css.createListing}>Dashboard</span>
                {/* {notificationDot} */}
              </NamedLink>
            ) : null}
            {searchToggle}
            {cartLink}
            {messagesLink}
            {favLink}
            {signupLink}
            {profileMenu}
          </div>
        </div>
      )}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
